import {IpLocation, IpNearby, SearchNearby} from "@/Api/Gd";
import {FriendLocationStore} from "@/views/Friend/FriendAddress/Store";
const key = "8d81eca6e74b4bcb93da0a36dae3f317"

/** 定位 获取周边 */
export function getLocation( ) {
    let locationData =  FriendLocationStore.getLocationData
    if ( !(locationData.longitude && locationData.latitude) )return Promise.resolve( [] );
    return Promise.resolve( IpNearby({ key,location:`${ locationData.longitude },${locationData.latitude}`,"page_num":FriendLocationStore.getLocationUpData.pageNo,"page_size":FriendLocationStore.getLocationUpData.pageSize }).then(res=>{
        return res.pois
    }) )
}

/** 搜索关键字 */
export function getSearchLocation( keywords:string ) {
    return Promise.resolve( SearchNearby({ key,keywords,region:FriendLocationStore.getLocationAddressData.addressComponent?.city || '',"city_limit":true,"page_num":FriendLocationStore.getLocationUpData.pageNo,"page_size":FriendLocationStore.getLocationUpData.pageSize }).then(res=>{
        return res.pois
    }) )
}

/** 定位获取 位置信息 */
export function getLocationSiteData() {
    let locationData =  FriendLocationStore.getLocationData
    return Promise.resolve( IpLocation({ key,location:`${ locationData.longitude },${locationData.latitude}`, }).then(res=>{
        FriendLocationStore.setLocationAddressData( res.regeocode )
        return res.regeocode
    }) )
}
