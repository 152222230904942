import Storage from "@/util/Storage";
import Axios from "@/Api/Config/shop";
import {FriendLocationStore} from "@/views/Friend/FriendAddress/Store";


declare const wx :any;

export function getConfig() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    if ( !(userId && token) )return Promise.resolve()
    return Promise.resolve(
        Axios({
            url:"/app/weixin/weixinApiConfig",
            method:"POST",
            headers:{
                userId,
                token
            },
            data:{
                url: window.location.href // "mobile.31shequ.com"//
            }
        }).then(res=>res.data)
    )
}

export default class JsSDK {
    constructor( data:any ) {
        let { That } = data
        getConfig().then(res=>{
            wx.config({
                debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.appid, // 必填，公众号的唯一标识
                timestamp: res.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.noncestr, // 必填，生成签名的随机串
                signature: res.signature,// 必填，签名
                jsApiList: ["getLocation","openLocation"] // 必填，需要使用的JS接口列表
            })
            wx.ready(()=>{
                this.getLocation()
            });
            wx.error(function(cat:any){
                That.title = "定位失败"
            });
        })
    }

    getLocation(){
        wx.getLocation({
            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res:any) {
            console.log(res)
                let latitude = res.latitude;
                let longitude = res.longitude;
                FriendLocationStore.setLocationData({ latitude,longitude })
            }
        });
    }
}
